import styledComponents from 'styled-components';
import THEME from '../../themes';

export const H2 = styledComponents.h2`
  text-align: ${THEME.TEXT.H2.DEFAULT_ALIGN};
  font: ${THEME.TEXT.H2.FONT};
  letter-spacing: ${THEME.TEXT.H2.LETTER_SPACING};
  color: ${THEME.TEXT.H2.COLOR};
  line-height: 1;
  margin: 0 0 30px 0;
`;

import * as React from 'react';
import styledComponents from 'styled-components';
import { Layout } from '../components/layout';
import { H2 } from '../components/titles';

const TopContainer = styledComponents.div`
  background: transparent linear-gradient(180deg, #FFFFFF00 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
  opacity: 1;
  width: 100%;
  padding-bottom: 40px;
  margin-bottom: 0;

  p {
    padding: 24px;
  }
`;

const NotFound = () => (
  <Layout title={'Clínica Dermatológica Angelucci - Página não encontrada'}>
    <main>
      <TopContainer>
        <br /><br /><br /><br />
        <H2>Erro 404 - Página não encontrada</H2>
        <p style={{ textAlign: 'center' }}>
          Infelizmente o endereço pesquisado não faz parte de nosso site.
          Use o menu acima para explorar todo o nosso conteúdo.
        </p>
        <br /><br /><br /><br />
      </TopContainer>
    </main>
  </Layout>
);

export default NotFound;
